import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Box, Button, styled, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { InvoicePayerViewModel } from '@rsmus/ecp-financeservice'
import TermsAndConditionsDialog from './TermsAndConditionsDialog'
import {
  getInvoicePayers,
  getPayOnAccountClient,
  PayOnAccountPayment,
  setPayableAmount,
  setPayOnAccountPayment,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import {
  getSelectedBankAccount,
  getSelectedCreditCard,
  getSelectedPayer,
  getSelectedPaymentMethod,
  getDoNotSave,
  setConfirmPaymentError,
  setSelectedPaymentMethod,
  setDoNotSave,
  setSelectedBankAccount,
  setSelectedPayer,
  setAccountSelectedType,
  setSelectedCreditCard,
  setNewPaymentMethod,
  SelectedAccountTypeState,
} from '../../../store/invoices/paymentInfoSlice'
import api from '../../../api'
import Spinner from '../../forms/Spinner/Spinner'
import PaymentMethod from './PaymentMethod'
import Payer from './PaymentComponents/Payer'
import { Styles } from '../../../types'
import AccountInfoSubText from './PaymentComponents/AccountInfoSubText'
import Heading from '../../typography/Heading/Heading'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import { CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT } from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import PaymentAmount from './PaymentComponents/PaymentAmount'
import Comments from './PaymentComponents/Comments'
import PaymentMethodRadioGroup from './PaymentComponents/PaymentMethodRadioGroup'
import PurchaseOrderQuoteNumber from './PaymentComponents/PurchaseOrderQuoteNumber'

const styles: Styles = {
  textContainer: (theme) => ({
    '.MuiOutlinedInput-root': {
      width: '100%',
      maxWidth: '100%',
    },
    width: 'calc(100% - 4rem)',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  fieldContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
    },
  }),
  link: (theme) => ({
    color: theme.palette.secondary.main,
    padding: 0,
  }),
}

const RowArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  [theme.breakpoints.only('mobile')]: {
    flexDirection: 'column',
  },
}))

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

interface PaymentTypePayNowProps {
  formSubmitCount: number
  payerChange: (payer: InvoicePayerViewModel | undefined) => void
  sessionCreating: () => void
  sessionChange: (sessionId: string, isError: boolean) => void
}

const PayOnAccount = ({
  formSubmitCount,
  payerChange,
  sessionCreating,
  sessionChange,
}: PaymentTypePayNowProps) => {
  const { reset, getValues } = useFormContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const invoicePayers = useSelector(getInvoicePayers)
  const doNotSave = useSelector(getDoNotSave)
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const selectedPayer = useSelector(getSelectedPayer)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const payOnAccountClient = useSelector(getPayOnAccountClient)
  const cemFeatures = useSelector(getCemFeatures)

  const [openTermsDialog, setOpenTermsDialog] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [paymentAmount, setPaymentAmount] = useState<number>(0)
  const [purchaseOrderQuoteNumber, setPurchaseOrderQuoteNumber] = useState<
    string | undefined
  >(undefined)
  const [commentsValue, setCommentsValue] = useState<string | undefined>(
    undefined,
  )

  const unselectedStatus: SelectedAccountTypeState = 'Unselected'

  const isAccountManager = useMemo(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  const resetPaymentMethodState = () => {
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayer(undefined))
    dispatch(setAccountSelectedType(unselectedStatus))
    dispatch(setNewPaymentMethod(false))
    dispatch(setDoNotSave(false))
  }

  const handleConfirmPayment = useCallback(async () => {
    setIsLoading(true)

    try {
      const clientId = Number(payOnAccountClient?.clientId)
      const response = await api.finance.payment_SubmitPayOnAccount({
        clientPaymentMethodId:
          selectedPaymentMethod === 'BankAccount'
            ? selectedBankAccount ?? 0
            : selectedCreditCard ?? 0,
        payerName: selectedPayer?.name,
        amount: paymentAmount,
        doNotSave,
        clientId,
        purchaseOrderQuoteNumber:
          purchaseOrderQuoteNumber && purchaseOrderQuoteNumber.length > 0
            ? purchaseOrderQuoteNumber
            : undefined,
        comments:
          commentsValue && commentsValue.length > 0 ? commentsValue : undefined,
      })

      if (response?.data?.isSuccess || false) {
        const payOnAccountPayment: PayOnAccountPayment = {
          accountNumber: response.data?.accountNumber,
          isSuccess: response.data?.isSuccess,
          transactionDate: response.data?.transactionDate,
          transactionId: response.data?.transactionId,
          clientId: response.data?.clientId,
          payer: selectedPayer?.name ?? '',
          paymentMethod: selectedPaymentMethod,
          accountName: payOnAccountClient?.name,
        }

        dispatch(setPayOnAccountPayment(payOnAccountPayment))
        dispatch(setPayableAmount(paymentAmount))

        reset()
        setIsLoading(false)
        resetPaymentMethodState()
        navigate('/invoicing/invoices/payment-success')
      } else {
        dispatch(setConfirmPaymentError(true))
        setIsLoading(false)
      }
    } catch {
      dispatch(setConfirmPaymentError(true))
      setIsLoading(false)
    }
  }, [
    doNotSave,
    paymentAmount,
    purchaseOrderQuoteNumber,
    commentsValue,
    selectedPaymentMethod,
    selectedBankAccount,
    selectedCreditCard,
    invoicePayers,
    selectedPayer,
    getValues,
    dispatch,
    reset,
    navigate,
  ])

  // When the form is submitted, open the terms and conditions dialog.
  useEffect(() => {
    if (formSubmitCount > 0) {
      setOpenTermsDialog(true)
    }
  }, [formSubmitCount])

  useEffect(() => {
    dispatch(setSelectedPaymentMethod('BankAccount'))
  }, [])

  const handleLinkClick = () => {
    resetPaymentMethodState()
    if (isAccountManager) {
      navigate(`/invoicing/account-management/${payOnAccountClient?.clientId}`)
    } else {
      navigate(`/invoicing/dashboard/${payOnAccountClient?.clientId}`)
    }
  }

  return (
    <Box style={{ width: '100%' }}>
      <Spinner open={isLoading} />
      <RowArea>
        <Box sx={styles.fieldContainer}>
          <StyledLabel sx={{ paddingBottom: 0 }}>
            {t('Invoicing.PayOnAccountPage.Account')}
            <span className="sr-only">{t('srOnlyRequired')}</span>
          </StyledLabel>
          <Heading variant="h2">{`${payOnAccountClient?.name} - ${payOnAccountClient?.clientId}`}</Heading>
          <Typography sx={{ fontSize: '1rem', lineHeight: '1.5rem' }}>
            {t('Invoicing.PayOnAccountPage.PayAnotherAccount')}{' '}
            <Button
              data-testid="Btn_PayOnAccount_ReturnLink"
              component="span"
              role="link"
              disableFocusRipple
              disableRipple
              sx={styles.link}
              onClick={handleLinkClick}>
              <Box component="span" sx={{ textDecoration: 'underline' }}>
                {' '}
                {isAccountManager
                  ? t('Navigation.AccountManagement')
                  : t('Navigation.Dashboard')}
              </Box>
            </Button>
            .
          </Typography>
        </Box>
      </RowArea>
      <RowArea>
        <Payer payerChange={payerChange} />
      </RowArea>
      <RowArea>
        <PaymentAmount
          handleChange={(amount: number) => setPaymentAmount(amount)}
          placeholder="USD only"
        />
      </RowArea>
      {payOnAccountClient?.isCreditCardEnabled && (
        <RowArea>
          <PaymentMethodRadioGroup />
        </RowArea>
      )}
      <AccountInfoSubText />
      <RowArea sx={{ marginTop: '0.5rem' }}>
        <PaymentMethod
          paymentMethodType={selectedPaymentMethod}
          isScheduledPayment={false}
          sessionCreating={sessionCreating}
          sessionChange={sessionChange}
        />
      </RowArea>
      <RowArea>
        <PurchaseOrderQuoteNumber
          handleChange={(val: string) => setPurchaseOrderQuoteNumber(val)}
        />
      </RowArea>
      <RowArea>
        <Comments
          handleChange={(comments: string) => setCommentsValue(comments)}
        />
      </RowArea>
      <TermsAndConditionsDialog
        isAutomaticPayment={false}
        open={openTermsDialog}
        onAccept={() => {
          setOpenTermsDialog(false)
          handleConfirmPayment()
        }}
        onClose={() => setOpenTermsDialog(false)}
        hasUserReadTerms={false}
      />
    </Box>
  )
}

export default PayOnAccount
