import { createTheme, ThemeOptions } from '@mui/material/styles'
import { merge } from 'lodash'
import tokens from './tokens.json'

export { tokens }

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    underline: true
    outlined: true
    contained: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    mobile: true
    tablet: true
    desktop: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    rsmBackground: Palette['primary']
    rsmBackgroundAccent: Palette['primary']
  }

  interface PaletteOptions {
    rsmBackground: PaletteOptions['primary']
    rsmBackgroundAccent: PaletteOptions['primary']
  }
}

export const themeDefinition: ThemeOptions = {
  breakpoints: {
    values: {
      xs: tokens.grid.mobile.breakpoint,
      sm: tokens.grid.mobile.breakpoint,
      md: tokens.grid.tablet.breakpoint,
      lg: tokens.grid.desktop.breakpoint,
      xl: tokens.grid.desktop.breakpoint,
      desktop: tokens.grid.desktop.breakpoint,
      tablet: tokens.grid.tablet.breakpoint,
      mobile: tokens.grid.mobile.breakpoint,
    },
  },
  palette: {
    text: {
      primary: tokens.colors.rsmText.primary,
    },
    warning: {
      main: tokens.colors.warning.main,
      contrastText: tokens.colors.warning.contrastText,
    },
    error: {
      main: tokens.colors.rsmRed.secondary,
      contrastText: tokens.colors.white,
    },
    common: {
      black: tokens.colors.rsmGray.copy,
      white: tokens.colors.white,
    },
    rsmBackground: {
      main: tokens.colors.floralWhite,
    },
    rsmBackgroundAccent: {
      main: tokens.colors.rsmBackgroundAccent.primary,
    },
  },
  typography: {
    fontFamily: tokens.type.fontFamilies.preloBook,
  },
  components: {
    MuiDivider: {
      defaultProps: {
        'aria-hidden': true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            marginTop: 0,
            height: 0,
          },
          '& .MuiFormHelperText-root.Mui-error': {
            color: 'red',
          },
          // overrides for search box variant
          '&.subvariant-search': {
            '& .MuiOutlinedInput-root': {
              border: '.0625rem solid',
              borderColor: tokens.colors.rsmGray.copy,
            },
            fieldset: {
              visibility: 'hidden',
            },
            '& .MuiOutlinedInput-input::placeholder': {
              color: tokens.colors.rsmGray.copy,
              opacity: 1,
            },
            '& .MuiOutlinedInput-input:focus-visible': {
              boxShadow: 'none !important',
            },
            '& .MuiInputBase-root:focus-within': {
              boxShadow: 'inset 0 0 0 .0625rem black !important',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          paddingBottom: '10px',
        },
        listbox: {
          padding: 0,
          borderRadius: 0,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: tokens.colors.rsmGray.disabled,
          '&& :hover': {
            color: tokens.colors.white,
            backgroundColor: tokens.colors.rsmGray.copy,
          },
        },
        noOptions: {
          color: tokens.colors.rsmText.primary,
          borderWidth: '0.0625rem',
          borderStyle: 'solid',
          padding: '0.6875rem',
          borderColor: '#707274',
          backgroundColor: tokens.colors.rsmGray.accessibility,
        },
        loading: {
          color: tokens.colors.rsmText.primary,
          borderWidth: '0.0625rem',
          borderStyle: 'solid',
          borderColor: '#707274',
          padding: '0.6875rem',
          backgroundColor: tokens.colors.rsmGray.accessibility,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'white',
          border: 'none',
          borderRadius: 0,
          maxWidth: '100%',
          minHeight: '50px',
          fontFamily: tokens.type.fontFamilies.preloBook,
          '&::placeholder': {
            color: tokens.colors.rsmGray.copy,
            opacity: 1,
          },
          '&.Mui-focused': {
            color: '',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${tokens.colors.rsmGray.dividers}`,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: tokens.colors.rsmRed.secondary,
          },
        },
        focused: {},
        notchedOutline: {},
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: tokens.colors.rsmRed.secondary,
            fontSize: 16,
            fontFamily: tokens.type.fontFamilies.preloBook,
            margin: 0,
            paddingTop: '8px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: tokens.type.fontFamilies.preloLight,
          color: tokens.colors.rsmGray.copy,
          textDecorationThickness: '5px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
          borderRadius: 0,
          border: '1px solid #979797',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: tokens.colors.rsmGray.copy,
          fontFamily: tokens.type.fontFamilies.preloLight,
          fontSize: '16px',
          lineHeight: '24px',
          ':hover': {
            color: 'white',
            backgroundColor: tokens.colors.rsmGray.copy,
            '& label': {
              color: 'white',
            },
          },
          ':focus-visible': {
            color: tokens.colors.white,
            backgroundColor: tokens.colors.rsmGray.copy,
            '& label': {
              color: 'white',
            },
          },
          '&.Mui-selected': {
            color: 'white',
            backgroundColor: tokens.colors.rsmGray.copy,
            ':hover': {
              color: tokens.colors.white,
              backgroundColor: tokens.colors.rsmGray.copy,
            },
            '& :focus-visible': {
              color: tokens.colors.white,
              backgroundColor: tokens.colors.rsmGray.copy,
            },
            ':focus': {
              color: tokens.colors.white,
              backgroundColor: tokens.colors.rsmGray.copy,
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Prelo-Book, sans-serif',
          fontSize: '16px',
          lineHeight: '24px',
          padding: '6px 10px',
          height: 'fit-content',
          maxHeight: '200px',
          overflow: 'hidden',
          whiteSpace: 'pre-line',
          textOverflow: 'ellipsis',
          minWidth: 10,
          minHeight: 40,
          margin: 0,
          border: '1px solid #979797',
          borderRadius: 0,
          backgroundColor: 'white',
          textAlign: 'center',
          color: tokens.colors.rsmGray.copy,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
          '.MuiFormControlLabel-label': {
            color: tokens.colors.rsmGray.copy,
            fontFamily: tokens.type.fontFamilies.preloBook,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontFamily: tokens.type.fontFamilies.preloMedium,
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            maxheight: '50px',
            fontSize: '18px',
            lineHeight: '24px',
            borderRadius: 0,
            paddingTop: '13px',
            paddingBottom: '13px',
            paddingLeft: '24px',
            paddingRight: '24px',
            ':hover': {
              backgroundColor: '#2B6C25',
              color: tokens.colors.white,
              borderColor: '#2B6C25',
            },
            ':active': {
              backgroundColor: tokens.colors.white,
              borderColor: tokens.colors.rsmGray.copy,
              color: '#000000CC',
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            maxheight: '50px',
            fontSize: '18px',
            lineHeight: '24px',
            borderRadius: 0,
            paddingTop: '13px',
            paddingBottom: '13px',
            paddingLeft: '24px',
            paddingRight: '24px',
            color: tokens.colors.white,
            ':hover': {
              backgroundColor: '#2B6C25',
            },
            ':active': {
              backgroundColor: '#63666A',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            maxheight: '50px',
            fontSize: '18px',
            lineHeight: '24px',
            borderRadius: 0,
            paddingTop: '13px',
            paddingBottom: '13px',
            paddingLeft: '24px',
            paddingRight: '24px',
            color: tokens.colors.white,
            borderColor: tokens.colors.white,
            ':hover': {
              backgroundColor: tokens.colors.white,
              color: '#1175A6',
              borderColor: '#1175A6',
            },
            ':active': {
              backgroundColor: tokens.colors.white,
              borderColor: tokens.colors.rsmGray.copy,
              color: '#000000CC',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            maxheight: '50px',
            fontSize: '18px',
            color: tokens.colors.rsmGray.copy,
            textTransform: 'none',
            borderRadius: 0,
            ':hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 0,
          padding: 0,
          textTransform: 'none',
        },
        textColorInherit: {
          color: tokens.colors.rsmGray.copy,
          opacity: 1,
          fontFamily: tokens.type.fontFamilies.preloBook,
          fontSize: tokens.type.fontSizes.desktop.body[0],
          lineHeight: tokens.type.fontSizes.desktop.body[1],
        },
        textColorPrimary: {
          color: tokens.colors.rsmGray.copy,
          opacity: 1,
          fontFamily: tokens.type.fontFamilies.preloBold,
          fontSize: tokens.type.fontSizes.desktop.body[0],
          lineHeight: tokens.type.fontSizes.desktop.body[1],
        },
        iconWrapper: {
          marginLeft: '15px',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          color: 'black !important',
        },
        root: {
          '&.MuiRadio-root > span > svg:first-of-type > path': {
            fill: tokens.colors.rsmGray.copy,
            stroke: '1px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderWidth: '2px',
          marginRight: '16px',
          marginBottom: '8px',
          height: '40px',
          borderRadius: '20px',
          paddingRight: '16px',
        },
        label: {
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '8px',
          paddingBottom: '8px',
          color: tokens.colors.rsmGray.copy,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root > div > p': {
            fontFamily: 'Prelo-Bold',
            fontSize: '18px',
            lineHeight: '24px',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
          '.MuiFormLabel-label': {
            color: tokens.colors.rsmGray.copy,
            fontFamily: tokens.type.fontFamilies.preloBold,
          },
          '&.Mui-focused': {
            color: tokens.colors.rsmGray.copy,
          },
        },
      },
    },
  },
}

export const normalTheme = createTheme(
  merge(themeDefinition, {
    palette: {
      primary: {
        main: tokens.colors.rsmBlue.primary,
      },
      secondary: {
        main: tokens.colors.rsmGreen.primary,
      },
    },
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: tokens.colors.rsmGreen.primary,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          outlined: {
            color: tokens.colors.rsmGreen.primary,
            borderColor: tokens.colors.rsmGreen.primary,
          },
          contained: {
            backgroundColor: tokens.colors.rsmGreen.primary,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: tokens.colors.rsmGreen.primary,
          },
          flexContainer: {
            height: '100%',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: tokens.colors.rsmBlue.primary,
            boxShadow: 'none',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderColor: tokens.colors.rsmBlue.primary,
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
        },
      },
    },
  }),
)

export const accessibilityTheme = createTheme(
  merge(themeDefinition, {
    palette: {
      primary: {
        main: tokens.colors.rsmBlue.accessibility,
      },
      secondary: {
        main: tokens.colors.rsmGreen.accessibility,
      },
    },
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: tokens.colors.rsmGreen.accessibility,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          outlined: {
            color: tokens.colors.rsmGreen.accessibility,
            borderColor: tokens.colors.rsmGreen.accessibility,
          },
          contained: {
            backgroundColor: tokens.colors.rsmGreen.accessibility,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: tokens.colors.rsmGreen.accessibility,
          },
          flexContainer: {
            height: '100%',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: tokens.colors.rsmBlue.accessibility,
            boxShadow: 'none',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderColor: tokens.colors.rsmBlue.accessibility,
          },
        },
      },
    },
  }),
)

export const documentRequestTheme = createTheme({
  ...themeDefinition,
  typography: {
    fontFamily: tokens.type.fontFamilies.preloBook,
    h1: {
      fontFamily: tokens.type.fontFamilies.preloLight,
      fontSize: '48px',
      color: tokens.colors.rsmText.primary,
      opacity: 1,
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '52px',
      xs: { fontSize: '40px', lineHeight: '44px' },
    },
    h2: {
      fontFamily: tokens.type.fontFamilies.preloBook,
      fontSize: '36px',
      color: tokens.colors.rsmText.primary,
      opacity: 1,
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '40px',
    },
    h3: {
      fontFamily: tokens.type.fontFamilies.preloBook,
      fontSize: '24px',
      color: tokens.colors.rsmText.primary,
      opacity: 1,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
    },
    h4: {
      fontFamily: tokens.type.fontFamilies.preloBook,
      fontSize: '18px',
      color: tokens.colors.rsmText.primary,
      opacity: 1,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
    },
    h5: {
      fontFamily: tokens.type.fontFamilies.preloBold,
      fontStyle: 'normal',
      color: tokens.colors.rsmText.primary,
      opacity: 1,
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    h6: {
      fontFamily: tokens.type.fontFamilies.preloBold,
      fontStyle: 'normal',
      color: tokens.colors.rsmText.primary,
      opacity: 1,
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontStyle: 'normal',
      color: tokens.colors.rsmText.primary,
      opacity: 1,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
    },
    body1: {
      fontStyle: 'normal',
      color: tokens.colors.rsmText.primary,
      opacity: 1,
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontStyle: 'normal',
      color: tokens.colors.rsmText.primary,
      opacity: 1,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
})
