import React, { ReactNode, Ref, useCallback, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PaymentMethodType, Styles } from '../../../types'
import api from '../../../api'
import { getHighContrast } from '../../../store/userSettings/userSettingsSlice'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'
import PaymentGatewayModal from '../Payments/PaymentGatewayModal'
import { PAYMENT_GATEWAY_IFRAME_URL } from '../../../envVariables'

const styles: Styles = {
  addPaymentMethod: (theme) => ({
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
    marginLeft: '-0.5rem',
  }),
}

export interface PaymentMethodNewProps {
  clientId: string | undefined
  paymentMethodType: PaymentMethodType
  sessionCreating?: () => void
  sessionChange?: (sessionId: string, isError: boolean) => void
  disabled?: boolean
  paymentsAccountsCount?: number
  accountAdded?: (paymentMethod: PaymentMethodType) => void
  children?: ReactNode
  trackNewPaymentMethod?: boolean
  buttonRef?: Ref<HTMLButtonElement> | null
}

const PaymentMethodNew = ({
  clientId,
  paymentMethodType,
  sessionCreating = () => {},
  sessionChange = () => {},
  disabled = false,
  paymentsAccountsCount = 0,
  accountAdded = () => {},
  children = null,
  trackNewPaymentMethod = false,
  buttonRef = null,
}: PaymentMethodNewProps) => {
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)
  const isHighContrast = useSelector(getHighContrast)
  const [paymentGatewayIframeSrc, setPaymentGatewayIframeSrc] = useState('')
  const [isUserOpeningPaymentModal, setIsUserOpeningPaymentModal] =
    useState(false)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)

  const getPaymentMethod = useCallback(() => {
    switch (paymentMethodType) {
      case 'BankAccount':
        return 'CHECK'
      case 'CreditCard':
        return userInfo.isEmployee ? 'SRED' : 'CARD'
      default:
        throw new Error('Invalid payment method type')
    }
  }, [paymentMethodType])

  const handleAddPaymentMethod = async () => {
    if (sessionCreating) {
      sessionCreating()
    }
    setIsUserOpeningPaymentModal(true)
    try {
      const sessionTokenId = await api.finance.payment_GetPaymentTokenSessionId(
        clientId?.replaceAll('-', ''),
        getPaymentMethod(),
        (paymentsAccountsCount ?? 0) + 1,
        isHighContrast,
      )

      if (sessionTokenId.data) {
        if (sessionChange) {
          sessionChange(sessionTokenId.data, false)
        }
        setPaymentGatewayIframeSrc(
          `${PAYMENT_GATEWAY_IFRAME_URL}?SESSIONID=${sessionTokenId.data}&PAGE=HOSTEDPAYMENT`,
        )
      } else {
        setPaymentGatewayIframeSrc('')
        setIsUserOpeningPaymentModal(false)
        if (sessionChange) {
          sessionChange('', true)
        }
      }
    } catch (error) {
      setPaymentGatewayIframeSrc('')
      setIsUserOpeningPaymentModal(false)
      if (sessionChange) {
        sessionChange('', true)
      }
    }
  }

  useEffect(() => {
    setPaymentGatewayIframeSrc('')
  }, [paymentMethodType])

  useEffect(() => {
    if (isUserOpeningPaymentModal && paymentGatewayIframeSrc) {
      setIsPaymentModalOpen(true)
    }
  }, [isUserOpeningPaymentModal, paymentGatewayIframeSrc])

  return (
    <>
      <Button
        ref={buttonRef}
        id={`Btn_PaymentMethod_AddNew${paymentMethodType}`}
        role="button"
        onClick={handleAddPaymentMethod}
        aria-label={t(`Invoicing.AddNew${paymentMethodType}`)}
        data-testid={`Btn_PaymentMethod_AddNew${paymentMethodType}`}
        disableRipple
        disabled={disabled}
        sx={styles.addPaymentMethod}>
        {children || t(`Invoicing.AddNew${paymentMethodType}`)}
      </Button>
      <PaymentGatewayModal
        closeHandler={() => {
          setIsUserOpeningPaymentModal(false)
          setIsPaymentModalOpen(false)
        }}
        refreshPaymentData={(paymentMethod) => {
          if (accountAdded) {
            accountAdded(paymentMethod)
          }
        }}
        open={isPaymentModalOpen}
        iframeSrc={paymentGatewayIframeSrc}
        paymentMethod={paymentMethodType}
        trackNewPaymentMethod={trackNewPaymentMethod}
      />
    </>
  )
}

export default PaymentMethodNew
