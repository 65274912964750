import React, { useState, useEffect } from 'react'
import i18n from '../../i18n'

export interface TimerProps {
  minutesRemaining: number
  onComplete?: () => JSX.Element | undefined
  on59secWarning?: () => JSX.Element | undefined
  on30secWarning?: () => JSX.Element | undefined
  on10secWarning?: () => JSX.Element | undefined
  on5secWarning?: () => JSX.Element | undefined
  onlyShowSeconds?: boolean
}

const CoundownTimer = ({
  minutesRemaining,
  onComplete = () => <span>{i18n.t('OutOfTime')}</span>,
  on59secWarning = () => <span>{i18n.t('OutOfTime')}</span>,
  on30secWarning = () => <span>{i18n.t('OutOfTime')}</span>,
  on10secWarning = () => <span>{i18n.t('OutOfTime')}</span>,
  on5secWarning = () => <span>{i18n.t('OutOfTime')}</span>,
  onlyShowSeconds = false,
}: TimerProps) => {
  const [secondsRemaining, setSeconds] = useState(minutesRemaining * 60)
  const [startTime] = useState(new Date())

  // Accounts for browser throttling that can occur on idle tabs
  // If timer is off by more than 5 seconds, adjusts to the actual time
  const checkTimeAccuracy = () => {
    const secondsElapsed = Math.round(
      (new Date().getTime() - startTime.getTime()) / 1000,
    )
    const ActualSecondsRemaining = minutesRemaining * 60 - secondsElapsed

    if (secondsRemaining - ActualSecondsRemaining > 5) {
      setSeconds(ActualSecondsRemaining)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  checkTimeAccuracy()

  const displayMinutes: string = Math.floor(secondsRemaining / 60)
    .toString()
    .padStart(2, '0')
  const displaySeconds: string = (secondsRemaining % 60)
    .toString()
    .padStart(2, '0')

  return (
    <div className="Timer" role="timer">
      {secondsRemaining > 0 ? (
        <div className="Time-Remaining">
          {!onlyShowSeconds || displayMinutes !== '00' ? displayMinutes : null}{' '}
          : {displaySeconds}{' '}
        </div>
      ) : (
        <div className="Time-Expired">
          <span>00 : 00</span>
          <br />
          {onComplete ? onComplete() : null}
        </div>
      )}
      {secondsRemaining < 60 && secondsRemaining > 30 && on59secWarning
        ? on59secWarning()
        : null}
      {secondsRemaining < 31 && secondsRemaining > 10 && on30secWarning
        ? on30secWarning()
        : null}
      {secondsRemaining < 11 && secondsRemaining > 5 && on10secWarning
        ? on10secWarning()
        : null}
      {secondsRemaining < 6 && secondsRemaining > 0 && on5secWarning
        ? on5secWarning()
        : null}
    </div>
  )
}

export default CoundownTimer
