import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  debounce,
  styled,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  ClientAssignmentsViewModel,
  ClientInfoViewModel,
  ClientViewModel,
  InvoicePayerViewModel,
} from '@rsmus/ecp-financeservice'
import SectionHeader from '../../layouts/SectionHeader'
import { Styles } from '../../../types'
import { useAppSelector } from '../../../utils/hooks'
import { tokens } from '../../../styles/materialTheme'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'
import {
  isCemFeatureEnabled,
  isFeatureFlagEnabled,
} from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
  CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
} from '../../../utils/constants/constants'
import {
  getCemFeatures,
  getUserInfo,
} from '../../../store/userInfo/userInfoSlice'
import api from '../../../api'
import { InvoiceSelectedFiltersConstants } from '../../../store/invoices/invoiceSelectedFiltersSlice'
import {
  PayOnAccountClientData,
  setInvoicePayers,
  setIsAccountInfoLoaded,
  setPayOnAccountClient,
  setPayingInvoices,
  setSelectedInvoices,
  setselectedAccInvoices,
  resetAll as resetSelectedInvoicesSlice,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { transformOpenInvoiceData } from '../../../store/invoices/invoiceSearchService'
import AccountUserIcon from '../../icons/AccountUserIcon/AccountUserIcon'
import { ArrowDownIcon } from '../../icons'
import { resetAll as resetPaymentInfoSlice } from '../../../store/invoices/paymentInfoSlice'

const styles: Styles = {
  cardContainer: (theme) => ({
    background: theme.palette.common.white,
    border: `1px solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    fontWeight: 500,
  }),
  welcomeHeader: {
    fontWeight: 700,
    fontSize: '1.875rem',
    lineHeight: '2.5rem',
    fontStyle: 'italic',
  },
  clientName: (theme) => ({
    fontWeight: 700,
    fontSize: '1.5rem',
    [theme.breakpoints.only('mobile')]: {
      fontSize: '1.2rem',
    },
  }),
  clientNumber: {
    fontWeight: 700,
    color: tokens.colors.rsmBlue.primary,
  },
  cellTitle: (theme) => ({
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: '2.5rem',
    [theme.breakpoints.only('mobile')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  }),
  amount: (theme) => ({
    fontWeight: 700,
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
    marginBottom: '.5rem',
    [theme.breakpoints.only('mobile')]: {
      fontSize: '1.375rem',
      lineHeight: '1.5rem',
    },
  }),
  otherPaymentOptions: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontStyle: 'italic',
  },
  otherPaymentOptionContainer: (theme) => ({
    paddingTop: '2rem',
    [theme.breakpoints.only('mobile')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start start',
      paddingTop: '1rem',
    },
  }),
  payNowButton: (theme) => ({
    width: '10.5rem',
    marginTop: '1rem',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
    },
  }),
  payOnAccountButton: (theme) => ({
    width: '10.5rem',
    marginTop: '1rem',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      marginTop: '0.5rem',
    },
  }),
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 50,
  },
  openAmountContainer: (theme) => ({
    [theme.breakpoints.only('mobile')]: {
      flex: '1 1 60%',
      marginTop: '1rem',
    },
  }),
  payNowContainer: (theme) => ({
    [theme.breakpoints.only('mobile')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  openAmountPayContainer: (theme) => ({
    display: 'inline-block',
    [theme.breakpoints.only('mobile')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
}

type GridProps = {
  clientSelected: boolean
  layoutContainer: 'dashboard' | 'accountManagement'
}

const GridWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, [col] 1fr)',
  gridTemplateRows: '[row] auto [row] auto [row] auto [row] auto [row]',
  gridGap: '3rem',
  [theme.breakpoints.only('tablet')]: {
    gridGap: '2rem',
  },
  [theme.breakpoints.down('tablet')]: {
    gridGap: '1rem',
  },
}))

const WelcomeBox = styled('div')(({ theme }) => ({
  gridColumn: 'col 1 / span 2',
  gridRow: 'row 1 / span 1',
  [theme.breakpoints.only('desktop')]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [theme.breakpoints.only('tablet')]: {
    gridColumn: 'col 3 / span 9',
    gridRow: 'row 1 / span 1',
  },
  [theme.breakpoints.down('tablet')]: {
    display: 'none',
  },
}))

const IconBox = styled('div')(({ theme }) => ({
  gridColumn: 'col 1 / span 2',
  gridRow: 'row 2 / span 2',
  [theme.breakpoints.only('desktop')]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [theme.breakpoints.only('tablet')]: {
    gridColumn: 'col 1 / span 2',
    gridRow: 'row 1 / span 4',
  },
  [theme.breakpoints.down('tablet')]: {
    display: 'none',
  },
}))

const ClientSelectorBox = styled('div')<GridProps>(
  ({ theme, clientSelected, layoutContainer }) => ({
    gridColumn: clientSelected ? 'col 3 / span 5' : 'col 3 / span 7',
    gridRow: 'row 2 / span 1',
    [theme.breakpoints.only('tablet')]: {
      gridColumn:
        // eslint-disable-next-line no-nested-ternary
        layoutContainer === 'dashboard'
          ? 'col 3 / span 6'
          : clientSelected
          ? 'col 3 / span 6'
          : 'col 3 / span 9',
      gridRow: 'row 2 / span 1',
    },
    [theme.breakpoints.down('tablet')]: {
      gridColumn: 'col 1 / span 12',
      gridRow: 'row 1 / span 1',
    },
  }),
)

const ClientNumberBox = styled('div')(({ theme }) => ({
  gridColumn: 'col 8 / span 2',
  gridRow: 'row 2 / span 2',
  [theme.breakpoints.only('tablet')]: {
    gridColumn: 'col 3 / span 6',
    gridRow: 'row 3 / span 1',
  },
  [theme.breakpoints.down('tablet')]: {
    gridColumn: 'col 1 / span 6',
    gridRow: 'row 2 / span 2',
  },
}))

const ClientAddressBox = styled('div')(({ theme }) => ({
  whiteSpace: 'pre-line',
  gridColumn: 'col 3 / span 3',
  gridRow: 'row 3 / span 1',
  [theme.breakpoints.only('tablet')]: {
    gridColumn: 'col 3 / span 6',
    gridRow: 'row 4 / span 1',
  },
  [theme.breakpoints.down('tablet')]: {
    gridColumn: 'col 7 / span 6',
    gridRow: 'row 2 / span 1',
  },
}))

const ClientBusinessPhoneBox = styled('div')(({ theme }) => ({
  gridColumn: 'col 6 / span 2',
  gridRow: 'row 3 / span 1',
  [theme.breakpoints.only('tablet')]: {
    display: 'none',
  },
  [theme.breakpoints.down('tablet')]: {
    display: 'none',
  },
}))

const PayBox = styled('div')<GridProps>(
  ({ theme, clientSelected, layoutContainer }) => ({
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start start',
    gridColumn: 'col 10 / span 3',
    gridRow: 'row 1 / span 4',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    borderLeft:
      clientSelected || layoutContainer === 'dashboard'
        ? `0.0156rem solid ${tokens.colors.rsmGray.dividers}`
        : 'none',
    [theme.breakpoints.only('tablet')]: {
      gridColumn: 'col 9 / span 4',
      gridRow: 'row 2 / span 4',
    },
    [theme.breakpoints.down('tablet')]: {
      alignItems: 'stretch',
      gridColumn: 'col 1 / span 12',
      gridRow: 'row 3 / span 1',
      borderLeft: 'none',
      paddingLeft: '0',
      paddingRight: '0',
      borderTop:
        clientSelected || layoutContainer === 'dashboard'
          ? `0.0156rem solid ${tokens.colors.rsmGray.dividers}`
          : 'none',
    },
  }),
)

const PayNowNoteBox = styled('div')(() => ({
  alignSelf: 'center',
  marginTop: '1rem',
  marginBottom: '1rem',
}))

const NoClientNoteBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  gridColumn: 'col 10 / span 3',
  gridRow: 'row 1 / span 4',
  [theme.breakpoints.only('tablet')]: {
    gridColumn: 'col 3 / span 9',
    gridRow: 'row 3 / span 1',
  },
  [theme.breakpoints.down('tablet')]: {
    gridColumn: 'col 1 / span 12',
    gridRow: 'row 3 / span 1',
  },
}))

export interface AccountInformationProps {
  layoutContainer: 'dashboard' | 'accountManagement'
}

const AccountInformation = ({ layoutContainer }: AccountInformationProps) => {
  const { t } = useTranslation()
  const { clientId = '' } = useParams()
  const cemFeatures = useSelector(getCemFeatures)
  const userInfo = useAppSelector(getUserInfo)
  const clientAdmin = useMemo(
    () =>
      isCemFeatureEnabled(CEM_FEATURE_PAYMENT_CLIENT_ADMIN, cemFeatures, 'any'),
    [cemFeatures],
  )
  const isAccountManager = useMemo(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  const invoicingEnabled = useMemo(
    () =>
      isCemFeatureEnabled(
        [
          CEM_FEATURE_PAYMENT_PAY_INVOICES,
          CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
          CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
        ],
        cemFeatures,
        'any',
      ),
    [userInfo, cemFeatures],
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()
  let abortController: AbortController | null = null
  const [totalOpenAmount, setTotalOpenAmount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [value, setValue] = useState<ClientViewModel | null>(null)
  const [primaryAddress, setPrimaryAddress] = useState<string>('')
  const [businessPhone, setBusinessPhone] = useState<string>('')
  const [client, setClient] = useState<ClientInfoViewModel | null>(null)
  const [clients, setClients] = useState<ClientViewModel[]>([])
  const [totalClientAssignments, setTotalClientAssignments] = useState<
    number | undefined
  >(undefined)

  const [openSearch, setOpenSearch] = useState<boolean>(false)
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const [clientList, setClientList] = useState<ClientViewModel[]>([])

  const fetchTotalOpenAmount = useCallback(async () => {
    try {
      setIsLoading(true)
      let openAmont = null
      if (!clientId && clientAdmin) {
        openAmont = await api.finance.invoice_GetTotalOpenAmount(
          InvoiceSelectedFiltersConstants.DefaultCurrency,
        )
      }
      if (clientId) {
        openAmont = await api.finance.invoice_GetClientTotalOpenAmount(
          InvoiceSelectedFiltersConstants.DefaultCurrency,
          clientId,
        )
      }

      if (openAmont?.data) {
        setTotalOpenAmount(openAmont.data)
      } else {
        setTotalOpenAmount(0)
      }
      setIsLoading(false)
    } catch (error) {
      setTotalOpenAmount(0)
      setIsLoading(false)
    }
  }, [clientId, clientAdmin, setTotalOpenAmount, setIsLoading])

  const clientChange = (newClientId: string) => {
    if (newClientId) {
      if (isAccountManager) {
        setOpenSearch(false)
      }
      if (layoutContainer === 'accountManagement') {
        navigate(`/invoicing/account-management/${newClientId}`)
        return
      }
      navigate(`/invoicing/dashboard/${newClientId}`)
    }
  }

  const fetchClientInfo = useCallback(async () => {
    try {
      let clientInfo: ClientInfoViewModel | undefined
      if (clientId) {
        clientInfo = (await api.finance.client_GetClientInfo(+clientId))?.data
      }

      if (clientInfo) {
        setClient(clientInfo)
        setValue({
          clientId,
          name: clientInfo.clientName,
          masterClientId: '',
        })
        const address = `${
          clientInfo.addressLine1 ? `${clientInfo.addressLine1}\n` : ''
        }${clientInfo.addressLine2 ? `${clientInfo.addressLine2}\n` : ''}${
          clientInfo.city
        }, ${clientInfo.stateProvince} ${clientInfo.country} ${
          clientInfo.postalCode
        }`
        setPrimaryAddress(address)
        setBusinessPhone(clientInfo.phoneNumber || '')
      } else {
        setPrimaryAddress('')
        setBusinessPhone('')
        setValue(null)
      }
      setIsLoading(false)
    } catch (error) {
      setPrimaryAddress('')
      setBusinessPhone('')
      setValue(null)
    }
  }, [
    api.finance.client_GetClientInfo,
    clientId,
    setClient,
    setPrimaryAddress,
    setBusinessPhone,
    setIsLoading,
  ])

  const fetchClients = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: ClientAssignmentsViewModel) => void,
        ) => {
          if (!request.input && isAccountManager) {
            callback({ totalClientAssignments: undefined, clients: [] })
            return
          }
          // Cancel the previous request before making a new request
          if (abortController) {
            abortController.abort('New request initiated')
          }
          abortController = new AbortController()
          const abortSignal = abortController.signal
          api.finance
            .client_GetClients(request.input, abortSignal)
            .then((response) => {
              callback(response?.data)
            })
        },
        500,
      ),
    [],
  )

  useEffect(() => {
    setIsSearchLoading(true)
    fetchClients({ input: inputValue }, (results) => {
      setClients([...(results?.clients || [])])
      setTotalClientAssignments(results?.totalClientAssignments)
      setIsSearchLoading(false)
    })
  }, [inputValue])

  useEffect(() => {
    fetchTotalOpenAmount()
    fetchClientInfo()
    if (isAccountManager && clientId.length > 0) {
      setOpenSearch(false)
    }
  }, [clientId])

  useEffect(() => {
    const allAccounts: ClientViewModel = {
      clientId: '0',
      name: 'All accounts',
      masterClientId: '',
    }
    const newClientList =
      layoutContainer === 'dashboard' && clientId
        ? [allAccounts, ...clients]
        : clients
    setClientList(newClientList)
  }, [clients, clientId, layoutContainer])

  const handlePayNow = async (e: SyntheticEvent) => {
    e.preventDefault()
    dispatch(resetSelectedInvoicesSlice())
    dispatch(resetPaymentInfoSlice())
    try {
      if (totalOpenAmount === 0) return
      setIsLoading(true)
      const invoices =
        !clientId && clientAdmin
          ? await api.finance.invoice_GetOpenInvoices(
              InvoiceSelectedFiltersConstants.DefaultCurrency,
            )
          : await api.finance.invoice_GetOpenClientInvoices(
              InvoiceSelectedFiltersConstants.DefaultCurrency,
              clientId,
            )

      if (invoices.data) {
        const invoiceData = transformOpenInvoiceData(invoices)
        dispatch(setSelectedInvoices(invoiceData))
        dispatch(setPayingInvoices(invoiceData))
        dispatch(setselectedAccInvoices(invoiceData))
        dispatch(setIsAccountInfoLoaded(true))
        navigate('/invoicing/invoices/pay-invoices')
      } else {
        dispatch(setSelectedInvoices([]))
        dispatch(setPayingInvoices([]))
      }
      setIsLoading(false)
    } catch (error) {
      dispatch(setSelectedInvoices([]))
      dispatch(setPayingInvoices([]))
      setIsLoading(false)
    }
  }

  const handlePayOnAccountAutoPay = async (
    e: SyntheticEvent,
    isPayOnAccount: boolean,
  ) => {
    e.preventDefault()
    try {
      setIsLoading(true)

      if (client) {
        const invoicePayers: InvoicePayerViewModel[] = []

        if (isAccountManager) {
          const invoicePayer: InvoicePayerViewModel = {
            name: client?.clientName,
            payerId: clientId,
            masterClientId: undefined,
          }
          invoicePayers.push(invoicePayer)
        }

        if (clientAdmin) {
          clients.forEach((clientItem: ClientViewModel) => {
            const invoicePayer: InvoicePayerViewModel = {
              name: clientItem?.name,
              payerId: clientItem?.clientId,
              masterClientId: clientItem?.masterClientId,
            }
            invoicePayers.push(invoicePayer)
          })
        }

        dispatch(setInvoicePayers(invoicePayers))
        const payOnAccountAutoPayClient: PayOnAccountClientData = {
          name: client?.clientName,
          clientId,
          isCreditCardEnabled: client?.creditCardEnabled,
          totalOpenAmount,
        }
        dispatch(setPayOnAccountClient(payOnAccountAutoPayClient))

        if (isPayOnAccount) {
          navigate('/invoicing/invoices/pay-on-account')
        } else {
          navigate('/invoicing/invoices/autopay')
        }
      }

      setIsLoading(false)
    } catch (error) {
      dispatch(setPayOnAccountClient(null))
      dispatch(setInvoicePayers([]))
      setIsLoading(false)
    }
  }

  const handleOpenSearch = () => {
    if (isAccountManager && inputValue.length > 0) {
      setOpenSearch(true)
    } else if (!isAccountManager) {
      setOpenSearch(true)
    }
  }

  return (
    <Box sx={styles.cardContainer}>
      <SectionHeader
        title={t('Invoicing.AccountInformation')}
        testId="Lbl_AccountInformation_Header"
      />
      {isLoading ? (
        <Box sx={styles.loading}>
          <CircularProgress
            size="3rem"
            color="secondary"
            data-testid="Spn_AccountInformation_Loading"
          />
        </Box>
      ) : (
        <GridWrapper>
          <WelcomeBox>
            <Box sx={styles.welcomeHeader} data-testid="Txt_Welcome_Header">
              {t('Invoicing.AccountInformationCard.Welcome')}
            </Box>
          </WelcomeBox>
          <IconBox>
            <AccountUserIcon />
          </IconBox>
          <ClientSelectorBox
            clientSelected={!!clientId}
            layoutContainer={layoutContainer}>
            {layoutContainer === 'dashboard' &&
            clientId &&
            totalClientAssignments &&
            totalClientAssignments === 1 ? (
              <Box component="span" sx={styles.clientName}>
                {clients?.[0]?.name}
              </Box>
            ) : (
              // If a clientId exists, wait until the client is loaded so the defaultValue shows the correct value (because it does not rerender).
              // Also, only render after 'clients' is loaded we don't flash the autocomplete when the user only has one client.
              (layoutContainer !== 'dashboard' ||
                (totalClientAssignments && totalClientAssignments > 1)) &&
              (!clientId || client) && (
                <Autocomplete
                  popupIcon={<ArrowDownIcon />}
                  id="payer"
                  fullWidth
                  onOpen={handleOpenSearch}
                  onFocus={() => {
                    setInputValue('')
                    setValue(null)
                  }}
                  onBlur={() => setOpenSearch(false)}
                  open={openSearch}
                  loading={isSearchLoading}
                  defaultValue={undefined}
                  value={value}
                  onChange={(
                    _: SyntheticEvent,
                    newValue: ClientViewModel | null,
                    reason: string,
                  ) => {
                    if (
                      newValue?.clientId === '0' ||
                      (clientId && reason === 'clear')
                    ) {
                      if (layoutContainer === 'dashboard') {
                        navigate('/invoicing/dashboard')
                      } else {
                        navigate('/invoicing/account-management')
                      }
                      return
                    }
                    setValue(newValue)
                    clientChange(newValue?.clientId || '')
                  }}
                  options={clientList}
                  getOptionLabel={(c) => {
                    if (c.clientId === '0') {
                      return c.name || ''
                    }
                    return c ? `${c.name} ( ${c.clientId} )` : ''
                  }}
                  isOptionEqualToValue={(option, val) =>
                    option?.clientId === val?.clientId
                  }
                  onInputChange={(_, newInputValue, reason) => {
                    if (reason === 'input') {
                      setInputValue(newInputValue)
                      if (isAccountManager) {
                        if (newInputValue.length > 0) {
                          setOpenSearch(true)
                        } else {
                          setOpenSearch(false)
                        }
                      }
                    }
                  }}
                  noOptionsText={t(
                    'Invoicing.AccountInformationCard.NoResults',
                  )}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      placeholder={
                        isAccountManager
                          ? t(
                              'Invoicing.AccountInformationCard.ClientAutocompleteInternalPlaceholder',
                            )
                          : t(
                              'Invoicing.AccountInformationCard.ClientAutocompleteExternalPlaceholder',
                            )
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isSearchLoading && inputValue && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )
            )}
          </ClientSelectorBox>
          {clientId && (
            <ClientNumberBox>
              <>
                <Box sx={styles.clientNumber}>
                  {t('Invoicing.AccountInformationCard.ClientNumber')}
                </Box>
                {clientId}
              </>
            </ClientNumberBox>
          )}
          {clientId && (
            <ClientAddressBox>
              <>
                <Box>
                  <b>
                    {t(
                      'Invoicing.AccountInformationCard.PrimaryAccountAddress',
                    )}
                  </b>
                </Box>
                {primaryAddress}
              </>
            </ClientAddressBox>
          )}
          {clientId && (
            <ClientBusinessPhoneBox>
              <>
                <Box>
                  <b>{t('Invoicing.AccountInformationCard.BusinessPhone')}</b>
                </Box>
                {businessPhone}
              </>
            </ClientBusinessPhoneBox>
          )}
          <PayBox clientSelected={!!clientId} layoutContainer={layoutContainer}>
            {((clientId && isAccountManager) || clientAdmin) && (
              <Box sx={styles.openAmountPayContainer}>
                <Box sx={styles.openAmountContainer} component="h3">
                  <Box
                    sx={styles.cellTitle}
                    data-testid="Txt_TotalOpenAmount_Title">
                    {t('Invoicing.TotalOpenAmount')}:
                  </Box>
                  <Box
                    sx={styles.amount}
                    data-testid="Txt_TotalOpenAmount_Amount">
                    {totalOpenAmount > 0
                      ? formatCurrency(
                          totalOpenAmount,
                          InvoiceSelectedFiltersConstants.DefaultCurrency,
                        )
                      : formatCurrency(
                          totalOpenAmount,
                          InvoiceSelectedFiltersConstants.DefaultCurrency,
                        ).split('.')[0]}{' '}
                    {InvoiceSelectedFiltersConstants.DefaultCurrency.toUpperCase()}
                  </Box>
                </Box>
                <Box sx={styles.payNowContainer}>
                  <Button
                    disabled={totalOpenAmount === 0}
                    disableFocusRipple
                    disableRipple
                    sx={styles.payNowButton}
                    variant="contained"
                    data-testid="Btn_AccountInformation_PayNow"
                    onClick={handlePayNow}>
                    {t('Invoicing.PayNow')}
                  </Button>
                </Box>
              </Box>
            )}
            {clientId && (
              <Box sx={styles.otherPaymentOptionContainer}>
                <Box
                  sx={styles.otherPaymentOptions}
                  data-testid="Txt_OtherPaymentOptions_Title">
                  {t('Invoicing.OtherPaymentOptions')}:
                </Box>
                <Button
                  disabled={!client}
                  disableFocusRipple
                  disableRipple
                  sx={styles.payOnAccountButton}
                  variant="contained"
                  data-testid="Btn_AccountInformation_PayOnAccount"
                  onClick={(e) => handlePayOnAccountAutoPay(e, true)}>
                  {t('Invoicing.PayOnAccount')}
                </Button>
                {isFeatureFlagEnabled('Invoicing') &&
                  invoicingEnabled &&
                  isFeatureFlagEnabled('Invoicing_Autopay') && (
                    <Box>
                      <Button
                        disabled={!client}
                        disableFocusRipple
                        disableRipple
                        sx={styles.payOnAccountButton}
                        variant="contained"
                        data-testid="Btn_AccountInformation_AutoPay"
                        onClick={(e) => handlePayOnAccountAutoPay(e, false)}>
                        {t('Invoicing.SetupAutoPay')}
                      </Button>
                    </Box>
                  )}
              </Box>
            )}
            {!clientId && clientAdmin && (
              <PayNowNoteBox>
                <Trans
                  i18nKey="Invoicing.AccountInformationCard.PayNowNote"
                  components={{ b: <b /> }}
                />
              </PayNowNoteBox>
            )}
          </PayBox>
          {!clientId && isAccountManager && (
            <NoClientNoteBox>
              <i>
                <Trans
                  i18nKey="Invoicing.AccountInformationCard.NoClientNote"
                  components={{ b: <b /> }}
                />
              </i>
            </NoClientNoteBox>
          )}
        </GridWrapper>
      )}
    </Box>
  )
}
export default AccountInformation
