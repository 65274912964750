import React from 'react'
import { FormLabel } from '@mui/material'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import DynamicView from '../../../rsmCoreComponents/components/DynamicView/DynamicView'
import { useGetUserContactPreferenceDetailsQuery } from '../../../store/userService'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, react/jsx-props-no-spreading
const FormLabelComponent = (props: any) => <FormLabel {...props} />

const ContactPreferenceView = () => {
  const userInfo = useSelector(getUserInfo)
  const { data } = useGetUserContactPreferenceDetailsQuery()

  const engFreqTxtKey =
    data?.data.engagementActivityNotificationFrequency?.frequencyTextKey
  const engagementFrequency = engFreqTxtKey
    ? `${t(
        `UserProfile.ContactPreferenceForm.NotificationFrequencies.${engFreqTxtKey}`,
      )}`
    : ''

  const insightFreqTxtKey =
    data?.data.insightsActivityNotificationFrequency?.frequencyTextKey
  const insightsFrequency = insightFreqTxtKey
    ? `${t(
        `UserProfile.ContactPreferenceForm.NotificationFrequencies.${insightFreqTxtKey}`,
      )}`
    : ''

  const timeZoneTxtKey = data?.data.timeZone?.timeZoneTextKey
  const regionOrTimeZone = timeZoneTxtKey
    ? `${t(`UserProfile.ContactPreferenceForm.TimeZones.${timeZoneTxtKey}`)}`
    : ''

  const contactMethodTxtKey = data?.data.preferredContactMethod?.contactMedium
  const contactMethod = contactMethodTxtKey
    ? `${t(
        `UserProfile.ContactPreferenceForm.ContactMethods.${contactMethodTxtKey}`,
      )}`
    : ''

  const clientBusinessNameValue = userInfo.isEmployee ? 'RSMUS' : 'RSMUS Client'

  const formValues = {
    name: `${userInfo.firstName} ${userInfo.lastName}`,
    businessEmail: userInfo.emailAddress,
    businessPhone: data?.data.businessPhone,
    clientBusinessName: clientBusinessNameValue,
    preferredContactMethod: contactMethod,
    engagementActivityNotificationFrequency: engagementFrequency,
    insightsActivityNotificationFrequency: insightsFrequency,
    timeZone: regionOrTimeZone,
    visited: data?.data.visited,
  }

  return (
    <DynamicView
      containerLayout={{
        container: true,
        justifyContent: 'center',
        alignItems: 'center',
        spacing: { xs: 1, sm: 2, md: 4, lg: 6 },
      }}
      definition={{
        name: {
          label: 'UserProfile.ContactPreferenceForm.Name',
          ariaLabel: 'UserProfile.ContactPreferenceForm.Name',
          component: FormLabelComponent,
          itemLayout: {
            item: true,
            lg: 4,
            md: 6,
            xs: 12,
          },
        },
        clientBusinessName: {
          label: 'UserProfile.ContactPreferenceForm.ClientOrBusinessName',
          ariaLabel: 'UserProfile.ContactPreferenceForm.ClientOrBusinessName',
          component: FormLabelComponent,
          itemLayout: {
            item: true,
            lg: 4,
            md: 6,
            xs: 12,
          },
        },
        timeZone: {
          label: 'UserProfile.ContactPreferenceForm.RegionOrTimeZone',
          ariaLabel: 'UserProfile.ContactPreferenceForm.RegionOrTimeZone',
          component: FormLabelComponent,
          itemLayout: {
            item: true,
            lg: 4,
            md: 6,
            xs: 12,
          },
        },
        businessPhone: {
          label: 'UserProfile.ContactPreferenceForm.BusinessPhoneNumber',
          ariaLabel: 'UserProfile.ContactPreferenceForm.BusinessPhoneNumber',
          component: FormLabelComponent,
          itemLayout: {
            item: true,
            lg: 4,
            md: 6,
            xs: 12,
          },
        },
        businessEmail: {
          label: 'UserProfile.ContactPreferenceForm.BusinessEmailAddress',
          ariaLabel: 'UserProfile.ContactPreferenceForm.BusinessEmailAddress',
          component: FormLabelComponent,
          itemLayout: {
            item: true,
            lg: 4,
            md: 6,
            xs: 12,
          },
        },
        preferredContactMethod: {
          label: 'UserProfile.ContactPreferenceForm.PreferredContactMethod',
          ariaLabel: 'UserProfile.ContactPreferenceForm.PreferredContactMethod',
          component: FormLabelComponent,
          itemLayout: {
            item: true,
            lg: 4,
            md: 6,
            xs: 12,
          },
        },
        engagementActivityNotificationFrequency: {
          label:
            'UserProfile.ContactPreferenceForm.EngagementActivityNotificationFrequency',
          ariaLabel:
            'UserProfile.ContactPreferenceForm.EngagementActivityNotificationFrequency',
          component: FormLabelComponent,
          itemLayout: {
            item: true,
            lg: 4,
            md: 6,
            xs: 12,
          },
        },
        insightsActivityNotificationFrequency: {
          label:
            'UserProfile.ContactPreferenceForm.InsightsActivityNotificationFrequency',
          ariaLabel:
            'UserProfile.ContactPreferenceForm.InsightsActivityNotificationFrequency',
          component: FormLabelComponent,
          itemLayout: {
            item: true,
            lg: 8,
            md: 6,
            xs: 12,
          },
        },
      }}
      initialValues={formValues}
    />
  )
}

export default ContactPreferenceView
