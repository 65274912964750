/* eslint-disable */
import React, { useCallback, useEffect, useRef } from 'react'
import { Modal as MuiModal, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import { Heading } from '../../typography'
import { CloseIcon } from '../../icons'

export type ModalProps = {
  isOpen: boolean | undefined
  children: any
  title?: string
  subTitle?: string
  closeHandler: () => void
}

const style = {
  position: 'absolute',
  top: '10%',
  left: 0,
  right: 0,
  margin: 'auto',
  width: '80%',
  bgcolor: '#fff',
  boxShadow: '1.5rem',
  maxWidth: '73.125rem',
  pt: '2rem',
  pb: '4rem',
  px: '2.5rem',
}

const MyBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
    margin: '0px',
  },
}))

const Modal = ({
  isOpen = false,
  closeHandler = () => {},
  children = null,
  title = '',
  subTitle = '',
}: ModalProps) => {
  const closeButtonRef = useRef<HTMLDivElement>(null)

  // a11y requirement, close only on enter and space
  const closeModal = useCallback(
    (event: any) => {
      if (event.keyCode === 13 || event.keyCode === 32) {
        closeHandler()
      }
    },
    [closeHandler],
  )

  useEffect(() => {
    closeButtonRef?.current?.focus()
  }, [closeButtonRef?.current])

  return (
    <MuiModal
      open={isOpen}
      onClose={closeHandler}
      data-testid="ecp_top_modal"
      aria-describedby="ecp_top_modal_title"
      role="dialog"
      sx={{
        overflowY: 'auto',
        zIndex: 3001, // should have highest Zindex as it overlays other items in site including hamburger menu
      }}>
      <MyBox sx={style}>
        <Box sx={{ position: 'relative', fontFamily: 'Prelo-Book' }}>
          <Box
            ref={closeButtonRef}
            data-testid="ecp_modal_close"
            sx={{
              position: 'absolute',
              top: '-1.5rem',
              right: '-1.5rem',
              fontSize: '1.5rem',
            }}
            role="button"
            tabIndex={0}
            aria-label="close"
            onClick={closeHandler}
            onKeyDown={(event: any) => closeModal(event)}>
            <CloseIcon />
          </Box>
          <Box>
            <Box>
              {title && (
                <Heading variant="h1WithH2Styling" id="ecp_top_modal_title">
                  {title}
                </Heading>
              )}
            </Box>
            <Box sx={{ marginTop: '.5rem' }}>
              {subTitle && (
                <Typography sx={{ lineHeight: '24px' }} variant="subtitle1">
                  {subTitle}
                </Typography>
              )}
            </Box>
          </Box>
          {children}
        </Box>
      </MyBox>
    </MuiModal>
  )
}

export default Modal
