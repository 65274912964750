import React, { ChangeEvent } from 'react'
import {
  Box,
  styled,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormContext, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  setSelectedPaymentMethod,
  setSelectedBankAccount,
  setSelectedCreditCard,
  setAccountSelectedType,
  SelectedAccountTypeState,
  setDoNotSave,
  setNewPaymentMethod,
} from '../../../../store/invoices/paymentInfoSlice'
import { Styles } from '../../../../types'

const styles: Styles = {
  PaymentMethodRadioRow: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 'auto',
  },
  rowAreaFixed: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 'auto',
  },
  radioLabel: (theme) => ({
    marginRight: '3.5rem',
    [theme.breakpoints.only('mobile')]: {
      marginRight: '1rem',
    },
  }),
  fieldContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
    },
  }),
}

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const PaymentMethodRadioGroup = () => {
  const { control } = useFormContext()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const UnselectedStatus: SelectedAccountTypeState = 'Unselected'

  const getRadioLabel = (isBankAccount: boolean): string => {
    let label = t('Invoicing.CreditCard')
    if (isBankAccount) {
      label = t('Invoicing.BankAccount')
    }
    return label
  }

  return (
    <FormControl component="fieldset" sx={styles.fieldContainer}>
      <Box component="legend">
        <StyledLabel htmlFor="paymentMethodType" data-testid="Lbl_RadioGroup">
          {t('Invoicing.PaymentMethod')}
          <span className="sr-only">{t('srOnlyRequired')}</span>
        </StyledLabel>
      </Box>
      <Controller
        name="paymentMethodType"
        control={control}
        defaultValue="BankAccount"
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            id="paymentMethodType"
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const val = e.target.value as 'BankAccount' | 'CreditCard'
              onChange(val)
              dispatch(setSelectedBankAccount(undefined))
              dispatch(setSelectedCreditCard(undefined))
              dispatch(setAccountSelectedType(UnselectedStatus))
              dispatch(setSelectedPaymentMethod(val))
              dispatch(setNewPaymentMethod(false))
              dispatch(setDoNotSave(false))
            }}
            defaultValue="BankAccount">
            <Box sx={styles.PaymentMethodRadioRow}>
              <Box key="BankAccount" sx={styles.rowAreaFixed}>
                <FormControlLabel
                  sx={styles.radioLabel}
                  value="BankAccount"
                  label={getRadioLabel(true)}
                  control={
                    <Radio
                      disableRipple
                      disableFocusRipple
                      key="BankAccount"
                      data-testid="Radio_btn_BankAccount"
                    />
                  }
                />
              </Box>
              <Box key="CreditCard" sx={styles.rowAreaFixed}>
                <FormControlLabel
                  sx={styles.radioLabel}
                  value="CreditCard"
                  label={getRadioLabel(false)}
                  control={
                    <Radio
                      disableRipple
                      disableFocusRipple
                      key="CreditCard"
                      data-testid="Radio_btn_CreditCard"
                    />
                  }
                />
              </Box>
            </Box>
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

export default PaymentMethodRadioGroup
