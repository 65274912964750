const minYear = new Date('50')
const maxYear = new Date((new Date().getFullYear() + 28).toString())
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidDate = (d: any) =>
  // isNaN and Number.isNaN have different behavior, so need to disable the eslint rule.
  // eslint-disable-next-line no-restricted-globals
  d instanceof Date && !isNaN(d as Date as unknown as number)

export const handleStartDateError = (
  startDate: Date | null,
  endDate: Date | null,
  setStartDateError: React.Dispatch<React.SetStateAction<boolean>>,
): boolean => {
  const hasError =
    (startDate !== null && !isValidDate(startDate)) ||
    (endDate !== null && startDate === null) ||
    (endDate !== null && startDate !== null && startDate > endDate) ||
    (startDate !== null && startDate < minYear) ||
    (startDate !== null && startDate > maxYear)

  if (hasError) {
    setStartDateError(true)
  } else {
    setStartDateError(false)
  }

  return hasError
}

export const handleEndDateError = (
  startDate: Date | null,
  endDate: Date | null,
  setEndDateError: React.Dispatch<React.SetStateAction<boolean>>,
): boolean => {
  const hasError =
    (endDate !== null && !isValidDate(endDate)) ||
    (endDate !== null && startDate !== null && endDate < startDate) ||
    (endDate === null && startDate !== null) ||
    (endDate !== null && endDate > maxYear)

  if (hasError) {
    setEndDateError(true)
  } else {
    setEndDateError(false)
  }

  return hasError
}

export const returnUTCFromLocalDate = (date: Date): Date => {
  const currentDate = new Date()
  date.setHours(currentDate.getHours())
  date.setMinutes(currentDate.getMinutes())
  date.setSeconds(currentDate.getSeconds())

  const utcDate = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )

  return new Date(utcDate)
}
